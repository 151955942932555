<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button v-checkbtn="isShowBtn(AUTH_BTN.goods_cate_add)" style="margin-bottom: 10px" type="primary" size="mini" icon="el-icon-plus" plain @click="goAddRoot"
      >新增</el-button
    >
    <el-table
      :data="list"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :header-row-style="{ height: '50px', color: '#626e7c' }"
      :header-cell-style="{ background: '#f8f9fb', padding: '8px 0' }"
      :cell-style="changeTr"
      :indent="30"
    >
      <el-table-column label="分类名称" align="left" min-width="120px">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图标" align="" min-width="120px">
        <template slot-scope="scope">
          <el-image style="width: 60px; height: 60px; display: block; border-radius: 2px" :src="`${scope.row.image_url}`" v-if="scope.row.image_url"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="状态" align="" min-width="120px">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 20" type="primary" size="small">上架</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="small">下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" align="" min-width="100px"> </el-table-column>
      <el-table-column label="操作" align="" width="150px" fixed="right">
        <template slot-scope="scope">
          <el-link v-checkbtn="isShowBtn(AUTH_BTN.goods_cate_add)" :underline="false" style="margin: 0 5px" v-if="scope.row.pid == 0" type="primary" @click="goAdd(scope.row)">新增</el-link>
          <el-link v-checkbtn="isShowBtn(AUTH_BTN.goods_cate_edit)" :underline="false" style="margin: 0 5px" type="primary" @click="goEdit(scope.row)">编辑</el-link>
          <el-link v-checkbtn="isShowBtn(AUTH_BTN.goods_cate_del)" :underline="false" style="margin: 0 5px" type="danger" @click="apiDelMenu(scope.row.id)">
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增编辑 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="width">
      <el-form ref="form" :model="formData" label-width="140px">
        <el-form-item label="上级分类：">
          <SelectGoodsCategory v-model="pid" :disabled="true"></SelectGoodsCategory>
        </el-form-item>
        <el-form-item label="分类名称：" prop="name" :rules="rules">
          <el-input style="width: 250px" v-model="formData.name" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="图标：" prop="image_url" :rules="rules" v-if="! pid">
          <UploadImg :url.sync="formData.image_url"></UploadImg>
        </el-form-item>
        <el-form-item label="状态：">
          <el-radio-group v-model="formData.status">
            <el-radio :label="10">下架</el-radio>
            <el-radio :label="20">上架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input-number v-model="formData.sort" :step="1" :min="1" size="mini" step-strictly></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_update = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getGoodsCategoryTreeAPI, deleteGoodsCategoryAPI, addGoodsCategoryAPI, editGoodsCategoryAPI, getGoodsCategoryDetailAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
import SelectGoodsCategory from './select-goods-category-mult.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { UploadImg, SelectGoodsCategory },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      list: [{ id: 1 }],
      total: 0,
      formData: {
        name: '',
        pid: 0,
        image_url: '',
        status: 20,
        sort: 1
      },
      pid: 0
    }
  },

  mounted() {
    this.getGoodsCategoryTree()
  },
  methods: {
    // 新增一级菜单
    goAddRoot() {
      this.pid = ''
      this.show_update = true
      this.formData = {
        name: '',
        pid: 0,
        image_url: '',
        status: 20,
        sort: 1
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // 新增子菜单
    goAdd({ id, pid }) {
      this.pid = id
      this.show_update = true
      this.formData = {
        name: '',
        pid: id,
        image_url: '',
        status: 20,
        sort: 1
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    async goEdit({ id, pid }) {
      this.pid = pid
      this.show_update = true
      this.formData = await getGoodsCategoryDetailAPI(id)
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editGoodsCategoryAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.show_update = false
              this.getGoodsCategoryTree()
            })
          } else {
            addGoodsCategoryAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.show_update = false
              this.getGoodsCategoryTree()
            })
          }
        }
      })
    },
    async getGoodsCategoryTree() {
      let res = await getGoodsCategoryTreeAPI()
      this.list = res || []
    },

    async apiDelMenu(id) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteGoodsCategoryAPI(id).then(() => {
            this.getGoodsCategoryTree()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 修改第一列无children的样式
    changeTr({ row, column, rowIndex, columnIndex }) {
      if (!row.children && columnIndex == 0) {
        return { paddingLeft: '23px' }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  // .el-table /deep/ {
  //   border-top-left-radius: 5px;
  //   border-top-right-radius: 5px;
  // }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
